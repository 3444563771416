import { MODULE } from "@/config/modules";
import { AuthService } from "@/services/auth-service";
import RouterOutView from "@/views/RouterOutView.vue";
import Vue from "vue";
import Router, { Route } from "vue-router";
import VueRouter from "vue-router";
import { UpgradeService } from "@/services/upgrade-service";

// Fix duplicate navigation errors
let originalPush = Router.prototype.push as (...args:any) => Promise<Route>;
Router.prototype.push = function(...args:any) {
	let result = originalPush.call(this, ...args);
	if (result) {
		result.catch((err:any) => err);
	}
	return result;
};


Vue.use(Router);

let editContactRoutes = (groupId: number) => [
	{path: "new", component: () => import("@/views/contacts/ContactEdit.vue"), props: {groupId}},
	{path: "edit/:id", component: () => import("@/views/contacts/ContactEdit.vue"), props: {groupId}},
	{path: "info/:id", component: () => import("@/views/contacts/ContactInfo.vue"), props: {groupId}},
];

export function checkHasModule(mod:MODULE, router:VueRouter):boolean {
	if (!AuthService.hasModule(mod)) {
		UpgradeService.showUpgradeModal(2);
		router.push(`/missing-module/${mod}`);
		return false;
	}
	return true;
}

function isLoggedInGuard(to:Route, from:Route, next:Function){
	if (AuthService.isLoggedIn){
		next();
		return;
	}
	next({
		path: `/login`
	});
}

async function logout(to:Route, from:Route, next:Function){
	await AuthService.logout();
	next({
		path: `/login`
	});
}

function isNotLoggedInGuard(to:Route, from:Route, next:Function){
	if (!AuthService.isLoggedIn){
		next();
		return;
	}
	next({
		path: `/home`
	});
}


const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/sale-url/:token", component: () => import("@/views/SaleFromUrl.vue"),
		},
		{
			path: "/client-portal/:token", component: ()=>import("@/views/TheClientPortal.vue")
		},
		{
			path: "/ticket-url/:token", component: () => import("@/views/tickets/TicketFromUrl.vue"),
		},
		{
			path: "/ubl-archive-url/:dossierId/:key", component: ()=> import("@/views/UblArchiveFromUrl.vue")
		},
		{
			path: "/pdf-archive-url/:dossierId/:key", component: ()=> import("@/views/PdfArchiveFromUrl.vue")
		},
		{
			path: "/signup", beforeEnter: isNotLoggedInGuard, component: () => import("@/views/TheSignUp.vue"),
		},
		{
			path: "/login", beforeEnter: isNotLoggedInGuard, component: ()=>import("@/views/TheLogin.vue"),
		},
		{
			path: "/logout", beforeEnter: logout,
		},
		{
			path: "/forgot-password", beforeEnter: isNotLoggedInGuard, component: ()=> import("@/views/TheForgotPassword.vue")
		},
		{
			path: "/account-activation/:token", component: () => import("@/views/AccountActivation.vue"),
		},
		{
			path: "/email-transfer-confirm/:token", component: ()=>import("@/views/AccountEmailTransfer.vue"),
		},
		{
			path: "/password-reset/:token", component: () => import("@/views/ThePasswordReset.vue")
		},
		{
			path: "/no-dossiers", component: ()=> import("@/views/NoDossiers.vue"),
		},
		{
			path: "/problem", component: () => import("@/views/TheProblem.vue"),
		},
		{
			path: "/", component: () => import("@/views/TheMain.vue"), beforeEnter: isLoggedInGuard, children: [
				{
					path: "", component: ()=>import("@/views/DefaultRoute.vue"),
					children: [
						{  path: "", redirect: "/start" },
						{
							path: "/start", component: () => import("@/views/home/QuickStartRoute.vue"),
						},
						{
							path: "/home/:year", component: () => import("@/views/home/HomeRoute.vue"),
						},
						{
							path: "/home", component: () => import("@/views/home/HomeRoute.vue"),
						},
						{
							path: "/account", component: () => import("@/views/account/AccountRoute.vue"),
							children: [
								{path: "",  redirect: "/account/price"},
								{path: "price", component: () => import("@/views/account/AccountPricing.vue")},
								{path: "pay", component: () => import("@/views/account/AccountPaymentMethods.vue")},
								{path: "invoices", component: ()=>import("@/views/account/AccountInvoices.vue")},
								{path: "settings", component: ()=>import("@/views/account/AccountSettings.vue")},
								{path: "activate", component: ()=>import("@/views/account/Activate.vue")},
								{path: "renew", component: ()=>import("@/views/account/Renew.vue")},
								{path: "upgrade", component: ()=>import("@/views/account/Upgrade.vue")},
								{path: "service-pack", component: ()=>import("@/views/account/ServicePack.vue")},
								{path: "plan-request-successfull", component: ()=>import("@/views/account/PlanRequestSuccessfull.vue")}
							]
						},
						{
							path: "/agenda",
							component: () => import("@/views/calendar/CalendarRoute.vue"),
						},
						{
							path: "/chat",
							component: () => import("@/views/chat/ChatRoute.vue"),
							children: [
								{path: "user/:userId", component: () => import("@/views/chat/ChatDirectMessages.vue")},
								{path: "channel/:channelId", component: () => import("@/views/chat/ChatChannel.vue")},
								{path: "threads", component: () => import("@/views/chat/ChatThread.vue")},
								{path: "thread/:threadId", component: () => import("@/views/chat/ChatThread.vue")}
							]
						},
						{path: "/tasks", component: () => import("@/views/tasks/TaskRoute.vue")},
						{path: "/tasks/:folderId", component: () => import("@/views/tasks/TaskRoute.vue")},
						{path: "/tasks/:folderId/:taskId", component: () => import("@/views/tasks/TaskRoute.vue")},
						{
							path: "/contacts", component: RouterOutView,
							children: [
								{
									path: "customers",
									component: () => import("@/views/contacts/CustomerBrowse.vue"),
									props: {browseId: 1},
									children: editContactRoutes(1)
								},
								{
									path: "suppliers",
									component: () => import("@/views/contacts/SupplierBrowse.vue"),
									props: {browseId: 2},
									children: editContactRoutes(2)
								},
								{
									path: "prospects",
									component: () => import("@/views/contacts/ProspectBrowse.vue"),
									props: {browseId: 3},
									children: editContactRoutes(3)
								},
							],
						},
						{
							path: "/products/composition/:id",
							component: () => import("@/views/products/ProductComposition.vue")
						},
						{
							path: "/products",
							component: () => import("@/views/products/ProductBrowse.vue"),
							props: {browseId: 4},
							children: [
								{path: "new", component: () => import("@/views/products/ProductEdit.vue")},
								{path: "edit/:id", component: () => import("@/views/products/ProductEdit.vue")},
								{path: "info/:id", component: () => import("@/views/products/ProductInfo.vue")}
							]
						},
						{
							path: "/stock", component: () => import("@/views/stock/StockRoute.vue"),
							children: [
								{
									path: "",
									component: () => import("@/views/stock/StockBrowse.vue"),
									props: {browseId: 5}
								},
								{path: "new", component: () => import("@/views/stock/StockEdit.vue")},
								{path: "edit/", component: () => import("@/views/stock/StockEdit.vue")},
								{path: "scan/", component: () => import("@/views/stock/StockScan.vue")},
							],
						},
						{
							path: "/stock-movement/:productId", component: ()=>import("@/views/stock/StockMovementBrowse.vue")
						},
						{
							path: "/crm", component: () => import("@/views/crm/CrmRoute.vue"),
							children: [
								{
									path: "",
									component: () => import("@/views/crm/CrmBrowse.vue"),
									props: {browseId: 11}
								},
								{path: "new", component: () => import("@/views/crm/CrmEdit.vue")},
								{path: "edit/:id", component: () => import("@/views/crm/CrmEdit.vue")},
							],
						},
						{
							path: "/cars",
							component: () => import("@/views/cars/CarBrowse.vue"),
							props: {browseId: 13},
							children: [
								{path: "new", component: () => import("@/views/cars/CarEdit.vue")},
								{path: "edit/:id", component: () => import("@/views/cars/CarEdit.vue")},
							],
						},
						{
							path: "/sales/detail",
							component: () => import("@/views/sales/SaleDetailBrowse.vue"),
							props: {browseId: 7}
						},
						{
							path: "/sales/view/:id",
							component: () => import("@/views/sales/SaleDetail.vue"),
						},
						{
							path: "/sales",
							component: () => import("@/views/sales/SaleBrowse.vue"),
							props: {browseId: 6},
							children: [
								{path: "new", component: () => import("@/views/sales/SaleEdit.vue")},
								{path: "edit/:id", component: () => import("@/views/sales/SaleEdit.vue")},
								{path: "info/:id", component: () => import("@/views/sales/SaleInfo.vue")}
							],
						},
						{
							path: "/purchase",
							component: () => import("@/views/purchase/PurchaseBrowse.vue"),
							props: {browseId: 9},
							children: [
								{path: "new", component: () => import("@/views/purchase/PurchaseEdit.vue")},
								{path: "edit/:id", component: () => import("@/views/purchase/PurchaseEdit.vue")},
								{path: "info/:id", component: () => import("@/views/purchase/PurchaseInfo.vue")},
								{path: "from-inboxreader/:inboxreaderId", component: () => import("@/views/purchase/PurchaseEdit.vue")},
							]
						},
						{
							path: "/subscriptions", component: RouterOutView,
							children: [
								{
									path: "",
									component: () => import("@/views/sales/SubscriptionBrowse.vue"),
									props: {browseId: 10},
									children: [
										{path: "new", component: () => import("@/views/sales/SubscriptionEdit.vue")},
										{path: "edit/:id", component: () => import("@/views/sales/SubscriptionEdit.vue")},
									]
								},
							]
						},
						{
							path: "/purchase-documents", component: RouterOutView,
							children: [
								{
									path: "",
									component: () => import("@/views/purchase/PurchaseDocumentBrowse.vue"),
									props: {browseId: 12},
									children: [
										{path: "new", component: () => import("@/views/purchase/PurchaseDocumentEdit.vue")},
										{path: "edit/:id", component: () => import("@/views/purchase/PurchaseDocumentEdit.vue")},
										{path: "info/:id", component: () => import("@/views/purchase/PurchaseDocumentInfo.vue")}
									]
								},
							],
						},
						{
							path: "/purchase-documents/detail",
							component: () => import("@/views/purchase/PurchaseDocumentRowBrowse.vue"),
							props: {browseId: 17}
						},
						//		{
						//			path: "/prospect-documents", component: RouterOutView,
						//			children: [
						//				{
						//					path: "",
						//					component: () => import("@/views/sales/ProspectDocumentBrowse.vue"),
						//					props: {browseId: 15},
						//					children: [
						//						{path: "new", component: () => import("@/views/sales/ProspectDocumentEdit.vue")},
						//						{path: "edit/:id", component: () => import("@/views/sales/ProspectDocumentEdit.vue")},
						//						{path: "info/:id", component: () => import("@/views/sales/ProspectDocumentInfo.vue")}
						//					]
						//				},
						//			],
						//		},
						{
							path: "/bookkeeping", component: RouterOutView,
							children: [
								{
									path: "",
									component: () => import("@/views/bookkeeping/Dashboard.vue")
								},
							],
						},
						{
							path: "/bank",
							component: ()=> import("@/views/bank/BankBrowse.vue"),
							props: {browseId: 8},
							children: [
								{path: "new", component: () => import("@/views/bank/BankEdit.vue")},
								{path: "edit/:id", component: () => import("@/views/bank/BankEdit.vue")},
								{path: "info/:id", component: () => import("@/views/bank/BankInfo.vue")},
							]
						},

						{
							path: "/diverse-ledgers",
							component: () => import("@/views/bookkeeping/DiverseLedgerBrowse.vue"),
							props: {browseId: 16},
							children: [
								{path: "new", component: () => import("@/views/bookkeeping/DiverseLedgerEdit.vue")},
								{path: "edit/:id", component: () => import("@/views/bookkeeping/DiverseLedgerEdit.vue")},
							],
						},
						{
							path: "/missing-module/:id", component: () => import("@/views/MissingModule.vue")
						},

					],
				},
				{
					path: "/cashier", component: ()=>import("@/views/cashier/Cashier.vue"),
					children: [

					]
				},{
					path: "/cashier-display", component: ()=>import("@/views/cashier/CashierDisplay.vue"),
					children: [

					]
				},
				{
					path: "/support", component: ()=>import("@/views/SupportRoute.vue"),
					children: [
						{path: "", component: () => import("@/views/tickets/TheTickets.vue")},
						{path: "roadmap", component: () => import("@/views/support/RoadmapDefault.vue"),
							children: [
								{path: ":id", component: () => import("@/views/support/RoadmapItem.vue")}
							]},
						{path: "teamviewer", component: () => import("@/views/support/TeamviewerDefault.vue")},
						{path: "tickets", component: () => import("@/views/tickets/TheTickets.vue"),
							children: [
								{path: "new", component: () => import("@/views/tickets/NewTicket.vue")},
								{path: ":id", component: () => import("@/views/tickets/TheTicket.vue")}
							]},
					]
				},
				{
					path: "/settings/mollie-connect-redirect", component: ()=>import("@/views/settings/default/MollieConnectRedirect.vue")
				},
				{
					path: "/settings", component: ()=>import("@/views/SettingsRoute.vue"),
					children: [
						{path: "", component: () => import("@/views/settings/SettingsDefault.vue")},
						{path: "kramp", component: ()=>import("@/views/settings/default/KrampSettings.vue")},
						{path: "sales", component: () => import("@/views/settings/SaleDefault.vue")},
						{path: "sales/types", component: () => import("@/views/settings/sale/SaleTypes.vue")},
						{path: "sales/types/:id", component: () => import("@/views/settings/sale/SaleType.vue")},
						{path: "sales/vats", component: ()=>import("@/views/settings/sale/Vats.vue")},
						{path: "sales/taxes", component: ()=>import("@/views/settings/sale/Taxes.vue")},
						{path: "browse", component: () => import("@/views/settings/BrowseDefault.vue")},
						{path: "browse/:id", component: () => import("@/views/settings/browse/Browse.vue")},
						{path: "browse/:id/view/new", component: () => import("@/views/settings/browse/ViewNew.vue")},
						{path: "notifications", component: () => import("@/views/settings/default/Notifications.vue")},
						{
							path: "browse/:id/view/:viewId",
							component: () => import("@/views/settings/browse/ViewEdit.vue")
						},
						{path: "common", component: () => import("@/views/settings/sale/Common.vue")},
						{path: "plugins", component: () => import("@/views/settings/PluginsDefault.vue"),
							children: [
								{path: "shop", component: () => import("@/views/settings/plugins/Shop.vue")},
								{path: "cashdro", component: () => import("@/views/settings/plugins/CashDro.vue")},
								{path: "display", component: () => import("@/views/settings/plugins/CashierDisplay.vue")},
								{path: "car", component: ()=>import("@/views/settings/plugins/CarpassSettings.vue")},
								{path: "sendcloud", component: ()=>import("@/views/settings/plugins/Sendcloud.vue")}
							]
						},
						{path: "sell-categories", component: () => import("@/views/settings/sale/SellCategories.vue")},
						{path: "friendly-ids", component: () => import("@/views/settings/default/FriendlyIds.vue")},
						{path: "sales/client-portal", component: () => import("@/views/settings/sale/ClientPortal.vue")},
						{path: "sales/valuta", component: () => import("@/views/settings/sale/Currencies.vue")},
						{path: "sales/client-portal/email", component: () => import("@/views/settings/sale/ClientPortalEmail.vue")},
						{path: "mails", component: () => import("@/views/settings/default/Mails.vue")},
						{path: "mails/edit/:id", component: () => import("@/views/settings/default/MailsEdit.vue")},
						{path: "sales/mails", component: () => import("@/views/settings/sale/Mails.vue")},
						{
							path: "sales/mail-template/new",
							component: () => import("@/views/settings/sale/MailTemplateEdit.vue")
						},
						{
							path: "sales/mail-template/edit/:id",
							component: () => import("@/views/settings/sale/MailTemplateEdit.vue")
						},
						{path: "decimals", component: () => import("@/views/settings/sale/Decimals.vue")},
						{path: "languages", component: () => import("@/views/settings/default/Languages.vue")},
						{path: "sale-layouts", component: () => import("@/views/settings/sale/SaleLayouts.vue")},
						{path: "sale-layout/new", component: () => import("@/views/settings/sale/SaleLayoutEdit.vue")},
						{path: "sale-layout/:id", component: () => import("@/views/settings/sale/SaleLayoutEdit.vue")},
						{path: "accounting", component: () => import("@/views/settings/default/Accounting.vue")},
						{path: "reminders", redirect: "/settings/reminders/0"},
						{path: "reminders/:tab", component: () => import("@/views/settings/default/Reminders.vue")},
						{
							path: "reminder-email-template/new",
							component: () => import("@/views/settings/default/ReminderEmailTemplateEdit.vue")
						},
						{
							path: "reminder-email-template/:id",
							component: () => import("@/views/settings/default/ReminderEmailTemplateEdit.vue")
						},
						{
							path: "reminder-layout/new",
							component: () => import("@/views/settings/default/ReminderLayoutEdit.vue")
						},
						{
							path: "reminder-layout/:id",
							component: () => import("@/views/settings/default/ReminderLayoutEdit.vue")
						},
						{path: "reports", component: () => import("@/views/settings/default/DefaultReports.vue")},
						{path: "ubl", component: () => import("@/views/settings/default/PluginUblSettings.vue")},
						{
							path: "stock",
							component: () => import("@/views/settings/default/StockSettings.vue")
						},
						{path: "bank", component: () => import("@/views/settings/default/Bank.vue")},
						{path: "crm", component: () => import("@/views/settings/default/Crm.vue")},
						{path: "journals", component: () => import("@/views/settings/default/Journals.vue")},
						{
							path: "products",
							component: () => import("@/views/settings/default/Products.vue")
						},
						{
							path: "products/settings",
							component: () => import("@/views/settings/default/ProductSettings.vue")
						},
						{
							path: "extra-field-names",
							component: () => import("@/views/settings/default/ExtraFieldNames.vue")
						},
						{
							path: "extra-field-names/clients",
							component: () => import("@/views/settings/default/ExtraFieldNamesEdit.vue"),
							props: {editKey: "ClientNames"}
						},
						{
							path: "extra-field-names/suppliers",
							component: () => import("@/views/settings/default/ExtraFieldNamesEdit.vue"),
							props: {editKey: "SupplierNames"}
						},
						{
							path: "extra-field-names/prospects",
							component: () => import("@/views/settings/default/ExtraFieldNamesEdit.vue"),
							props: {editKey: "ProspectNames"}
						},
						{
							path: "extra-field-names/products",
							component: () => import("@/views/settings/default/ExtraFieldNamesEdit.vue"),
							props: {editKey: "ProductNames"}
						},
						{
							path: "extra-field-names/sales",
							component: () => import("@/views/settings/default/ExtraFieldNamesEdit.vue"),
							props: {editKey: "SaleNames"}
						},
						{
							path: "extra-field-names/purchases",
							component: () => import("@/views/settings/default/ExtraFieldNamesEdit.vue"),
							props: {editKey: "PurchaseNames"}
						},
						{
							path: "users",
							component: () => import("@/views/settings/default/Users.vue")
						},
						{
							path: "users/new",
							component: () => import("@/views/settings/default/UserEdit.vue")
						},
						{
							path: "users/:id",
							component: () => import("@/views/settings/default/UserEdit.vue")
						},
						{
							path: "users/views/:id",
							component: () => import("@/views/settings/default/UserEditBrowseList.vue")
						},
						{
							path: "users/views/:userId/:browseId",
							component: () => import("@/views/settings/default/UserEditViewsList.vue")
						},
						{
							path: "users/views/:userId/:browseId/new",
							component: () => import("@/views/settings/default/UserEditView.vue")
						},
						{
							path: "users/views/:userId/:browseId/:viewId",
							component: () => import("@/views/settings/default/UserEditView.vue")
						},
						{
							path: "company",
							component: ()=>import("@/views/settings/default/CompanySettings.vue")
						},
						{
							path: "company/dossier/new",
							component: ()=>import("@/views/settings/default/DossierEdit.vue")
						},
						{
							path: "company/dossier/:id",
							component: ()=>import("@/views/settings/default/DossierEdit.vue")
						},
						{
							path: "api-keys",
							component: ()=>import("@/views/settings/default/ApiKeys.vue")
						},
						{
							path: "contacts",
							component: ()=> import("@/views/settings/default/ContactSettings.vue")
						},
						{
							path: "units",
							component: ()=>import("@/views/settings/default/UnitSettings.vue")
						},
						{
							path: "backup",
							component: ()=>import("@/views/settings/default/Backup.vue")
						},
						{
							path: "product-groups",
							component: ()=>import("@/views/settings/default/ProductGroups.vue")
						},
					],
				},
			],
		},
	],
});

/*
router.beforeEach((to: Route, from: Route, next) => {
	if (from.query.electronPort != to.query.electronPort && to.query.electronPort == undefined) {
		return next({
			path: to.path,
			query: {
				...to.query,
				electronPort: (from.query.electronPort || to.query.electronPort)
			}
		});
	}
	next();
});*/

export default router;