import { UserConfigService } from "@/services/user-config-service";
import { getPreloadsFromFilters, HumanFilter } from "@/utils/human-filter";
import { browseFieldPathToFilterFieldPath } from "@/utils/models/utils";
import { IAdvancedQueryParams } from "@/utils/query-params";
import { View } from "@/models/view";
import { Axios } from "../axios";
import { AxiosResponse } from "axios";
import { getModel, Model } from "../models/model";
import { BrowseService } from "@/services";

function processFilters(filters:HumanFilter[], query:IAdvancedQueryParams):any[]{
	if (filters.length > 0) {
		filters[0].IsOr = false;
	}
	let model = getModel(query.view.Table);
	return filters.map(f=>f.getProcessedJson(model));
}

function getQuickFilters(query: IAdvancedQueryParams):HumanFilter[] {
	if (query.searchQuery == "") return [];
	let browse = BrowseService.getBrowseWithoutViewSettings(query.view.BrowseID);
	let model:Model | null = null;
	if (browse){
		model = getModel(browse.Table);
	}
	if (query.searchByAllColumns) {
		return HumanFilter.getQuickFilters(query.view.Columns.filter(f => !f.IsComputed).map((c) => browseFieldPathToFilterFieldPath(c.Property)), query.searchQuery || "", model);
	}
	let settings = UserConfigService.getBrowseSearchSettings();
	let fields = settings.getSettings(query.view.BrowseID);
	return HumanFilter.getQuickFilters(fields, query.searchQuery || "", model);
}

export async function advancedSearch(query: IAdvancedQueryParams, url: string, additionalFilters:HumanFilter[] = [], additionalHeaders?:any):Promise<AxiosResponse> {
	let extraFilters = getQuickFilters(query);
	let filters = [...extraFilters, ...(query.filters || [])];
	filters.push(...additionalFilters);
	let preloads = getPreloadsFromFilters(filters);

	let params = {
		limit: query.limit,
		order: browseFieldPathToFilterFieldPath(query.orderBy),
		orderDirection: query.orderDirection,
		filters: processFilters(filters, query),
		preloads,
		offset: query.offset,
		searchQuery: query.searchQuery,
		autoWildCardSearch: query.autoWildCardSearch,
		searchByAllColumns: query.searchByAllColumns,
		dontSaveSettings: additionalFilters.length > 0 || query.dontSaveViewSettings ? "true" : "false"
	} as any;

	let result = await Axios.get(`${url}/${query.view.ID}`, {params, headers: additionalHeaders || {}});
	return result;
}

export async function advancedSearchStaticView(view:View, query: IAdvancedQueryParams, url: string, additionalFilters:HumanFilter[] = [], additionalHeaders?:any):Promise<AxiosResponse> {
	let extraFilters = getQuickFilters(query);

	view.computePreloads();
	let filters = [...extraFilters, ...(query.filters || [])];
	filters.push(...additionalFilters);
	let preloads = getPreloadsFromFilters(filters);

	let params = {
		limit: query.limit,
		order: browseFieldPathToFilterFieldPath(query.orderBy),
		orderDirection: query.orderDirection,
		filters: processFilters(filters, query),
		preloads,
		page: query.offset,
		searchQuery: query.searchQuery,
		autoWildCardSearch: query.autoWildCardSearch,
		dontSaveSettings: additionalFilters.length > 0 || query.dontSaveViewSettings ? "true" : "false",
		view: view.getJSON()
	} as any;

	let result = await Axios.get(`${url}`, {params, headers: additionalHeaders || {}});
	return result;
}
