import { GoogleService } from "@/services/google-service";
import { NotificationService } from "@/services/notification-service";
import { UserConfigService } from "@/services/user-config-service";
import { VatService } from "@/services/vat-service";
import { tokenStore } from "@/utils/token-store";
import { ServerConfig } from "../config/config";
import { BrowseService } from "./browse-service";
import { DossierConfigService } from "./dossier-config-service";
import { JobService } from "./job-service";
import { MainSocketService, EVENT_SWITCH_SESSION } from "./main-socket-service";
import { AuthSocketService } from "./auth-socket-service";
import { UserInfo } from "@/models/dossier/user-info";
import { Axios, UnauthenticatedClient, createAxiosClient, refreshTokensIfRequired } from "@/utils/axios";
import { Company } from "@/models/company";
import { Dossier } from "@/models/dossier/dossier";
import router from "@/router";
import { EventEmitter } from "@/utils/event-emitter";
import { HttpError } from "@/utils/services/http-error";
import { BookYearService } from "./book-year-service";
import { SaleService } from "./sale-service";
import { UnitService } from "./unit-service";
import { BankService } from "./bank-service";
import { ViewService } from "./view-service";
import { DossierService } from "./dossier-service";
import { TicketService } from "./ticket-service";
import { ElectronCtep } from "@/utils/electron/electron-ctep";
import { BrowserInfo } from "@/utils/browser";
import { ElectronFunctions } from "@/utils/electron/electron";
import {CurrencyService} from "@/services/currency-service";
import { InitService } from "./init-service";
import { ClosedPeriodService } from "./closed-period-service";
import { CrmService } from "./crm-service";
import Vue from "vue";
import { v4 } from "uuid";

const unauthenticatedClient = createAxiosClient({skipAuthentication: true});

export interface ILoginOptions {
	workspaceUrl?:string;
	totp?:string;
	userDeviceConfrimSet?:boolean;
}


export class AuthServiceClass extends EventEmitter {
	private broadcastChannel:BroadcastChannel = new BroadcastChannel("auth")
	public mainVueInstance:Vue = null as any as Vue;
	public wfUser:UserInfo = new UserInfo();
	public wfCompany:Company = new Company();
	public wfDossier:Dossier = new Dossier();
	public isLoggedIn:boolean = false;
	private devModeEnabled:boolean = false;
	public get isDevModeEnabled():boolean {
		if (this.devModeEnabled) return true;
		return localStorage.getItem("dev-mode") == "true";
	}
	private refreshingTokens:boolean = false;
	private _appReady:boolean = false;
	public get appReady():boolean {
		return this._appReady;
	}
	private set appReady(value:boolean) {
		this._appReady = value;
		for (let i = this.appReadyPromises.length -1; i>=0; i--){
			this.appReadyPromises[i]();
			this.appReadyPromises.splice(i, 1);
		}
	}
	private appReadyPromises:Function[] = [];
	private failedRefreshCount:number = 0 ;

	private readonly baseUrl:string = `${ServerConfig.auth}/auth`;
	private readonly totpUrl:string = `${this.baseUrl}/totp`;

	// Used for browse-extra-buttons.ts
	get tokenStore(){
		return tokenStore;
	}
	// Used for browse-extra-buttons.ts
	get refreshTokensIfRequired(){
		return refreshTokensIfRequired;
	}

	public constructor(){
		super();
		if (!localStorage.getItem("socket-id")){
			localStorage.setItem("socket-id", v4());
		}
		this.broadcastChannel.addEventListener("message", (message:MessageEvent)=>{
			if (message.data == "logout") {
				window.location.reload();
			}
			if (message.data == "login") {
				window.location.reload();
			}
		});
	}


	public get isDossierInitialized():boolean {
		if (!this.appReady) {
			return false;
		}
		if (this.wfCompany.isExpired()){
			return true;
		}
		return DossierConfigService.getGeneralConfig().HideInitModal;
	}



	async forgotPassword(email:string):Promise<void>{
		try {
			await unauthenticatedClient.post(`${this.baseUrl}/forgot-password`, {email});
		}catch(err){
			let error = err as HttpError;
			if (error.status == 404) {
				error.dontShow =  true;
			}
		}
	}

	async resetPassword(token:string, newPassword:string):Promise<void>{
		await unauthenticatedClient.post(`${this.baseUrl}/do-password-reset`, {token, newPassword});
	}

	async changePassword(oldPassword:string, newPassword:string):Promise<void>{
		await Axios.post(`${this.baseUrl}/change-pass`, {OldPassword: oldPassword, NewPassword: newPassword});
	}

	async confirmChangeEmail(token:string):Promise<void>{
		await unauthenticatedClient.post(`${this.baseUrl}/confirm-update-email/${token}`);
	}

	async resendActivationEmail():Promise<void>{
		await Axios.post(`${this.baseUrl}/resend-activation-email`);
	}

	async updateProfileInfo(firstname:string, lastname:string, username:string, phone:string, email:string):Promise<UserInfo>{
		let result = await Axios.post(`${this.baseUrl}/update-account-info`, {
			FirstName: firstname,
			LastName: lastname,
			UserName: username,
			Phone: phone,
			Email: email,
		});
		return new UserInfo(result.data);
	}

	async setProfileImg(img:File):Promise<UserInfo>{
		let form = new FormData();
		form.set("file", img, img.name);
		let result = await Axios.post(`${this.baseUrl}/set-profile-img`, form);
		this.wfUser = new UserInfo(result.data);
		return this.wfUser;
	}

	async deleteProfileImg():Promise<UserInfo>{
		let result = await Axios.post(`${this.baseUrl}/remove-profile-img`);
		this.wfUser = new UserInfo(result.data);
		return this.wfUser;
	}

	hasModule(moduleId: number): boolean {
		return this.wfCompany.hasModule(moduleId);
	}

	async hasRemainingSales(): Promise<boolean> {
		if(this.wfCompany.Tier !== -1){
			return true;
		}
		return SaleService.verifyRemainingSales();
	}

	async signup(input: any): Promise<void> {
		await unauthenticatedClient.post(this.baseUrl + "/signup", {
			firstname: input.firstname,
			lastname: input.lastname,
			companyname: input.companyname,
			email: input.email,
			phone: input.phone,
			password: input.password,
		});
	}

	async activate(token: string): Promise<UserInfo> {
		let result = await unauthenticatedClient.post(this.baseUrl + "/activate", {token});
		return new UserInfo(result.data);
	}

	// eslint-disable-next-line max-lines-per-function
	async login(username: string, password: string, options:ILoginOptions = {}) {
		let userDeviceId ="";
		let userDeviceName = "";
		let userDeviceConfrimSet = false;
		if (BrowserInfo.isElectron()){
			let res = await ElectronFunctions.getSerialNumber();
			userDeviceId = res.key;
			userDeviceName = res.name;
			userDeviceConfrimSet = options.userDeviceConfrimSet == true;
		}
		let response = await unauthenticatedClient.post(this.baseUrl + "/login", {
			username,
			password,
			workspace: options.workspaceUrl,
			totpPassword: options.totp,
			userDeviceId,
			userDeviceName,
			userDeviceConfrimSet
		});
		tokenStore.refreshToken = response.data.RefreshToken.Token;
		if (response.data.AccessTokens.Tokens.length == 0) {
			return this.onNoDossiers();
		}
		tokenStore.accessTokens = response.data.AccessTokens.Tokens.map((t: any) => {
			return {
				dossierName: t.DossierName,
				token: t.Token,
			};
		});
		tokenStore.tokenExpires = new Date(response.data.AccessTokens.Expires * 1000);
		tokenStore.refreshTokenExpires = new Date(response.data.RefreshToken.Expires * 1000);
		tokenStore.serverTime = new Date(response.data.ServerTime);
		this.wfDossier = new Dossier(response.data.AccessTokens.Tokens[0]);
		tokenStore.selectedDossier = this.wfDossier.name;
		this.isLoggedIn = true;
		this.devModeEnabled = response.data.AccessTokens.DevModeEnabled;
		await this.getUserInfo();
		await this.selectDossier(tokenStore.accessTokens[0].dossierName);
		router.push("/");
		this.broadcastChannel.postMessage("login");
	}

	async selectDossier(newDossier: string) {
		if (tokenStore.accessTokens.map(t => t.dossierName).indexOf(newDossier) == -1) {
			throw new Error("Dossier does not exist");
		}
		this.appReady = false;
		await MainSocketService.disconnect();
		await AuthSocketService.disconnect();
		await ElectronCtep.deinit();
		tokenStore.selectedDossier = newDossier;
		if (this.wfDossier){
			this.wfDossier.name = newDossier;
		}
		await this.getUserInfo();
		await this.initServices();
	}

	private onTokensRefreshed():Promise<boolean>{
		return new Promise((resolve:Function, reject:Function) => {
			this.once("refresh-tokens-done", (success:boolean) => {
				if (success) {
					resolve(success);
				}else{
					reject();
				}
			});
		});
	}

	private onNoDossiers(){
		router.push("/no-dossiers");
		this.logout();
		this.emit("error", "no-dossiers");
	}

	// eslint-disable-next-line max-lines-per-function
	public async refreshTokens() {
		if (this.refreshingTokens) {
			return this.onTokensRefreshed();
		}
		try {
			this.refreshingTokens = true;
			let response = await unauthenticatedClient.post(this.baseUrl + "/refresh-token", {}, {
				headers: {
					Authorization: tokenStore.refreshToken
				}
			});
			tokenStore.accessTokens = response.data.Tokens.map((t: any) => {
				return {
					dossierName: t.DossierName,
					token: t.Token,
				};
			});
			tokenStore.tokenExpires = new Date(response.data.Expires * 1000);
			tokenStore.serverTime = new Date(response.data.ServerTime);
			this.refreshingTokens = false;
			this.devModeEnabled = response.data.DevModeEnabled;
			MainSocketService.updateQuery();
			AuthSocketService.updateQuery();
			this.emit("refresh-tokens-done", true);
		} catch (err) {
			let error = err as HttpError;
			if (error.status == 401 || (error.status == 404 && error.key == "USER_NOT_FOUND")) {
				error.dontShow =  true;
				this.logout();
			}
			this.emit("refresh-tokens-done", false);
			throw err;
		}finally{
			this.refreshingTokens = false;
		}
	}

	async getUserInfo() {
		let postResult = await Axios.get(this.baseUrl);
		this.wfUser = new UserInfo(postResult.data.User);
		this.wfDossier = new Dossier(postResult.data.Dossier);
		this.wfCompany = new Company(postResult.data.Company);
		tokenStore.firstName = this.wfUser.Username.split(/ (.+)/)[0];
	}

	async logout() {
		this.isLoggedIn = false;
		try {
			GoogleService.logout();
			await unauthenticatedClient.post(ServerConfig.auth + "/auth/logout", {refreshToken: tokenStore.refreshToken});
		} catch (err) {
			let e = err as HttpError;
			e.dontShow = true;
		}
		sessionStorage.clear();
		tokenStore.accessTokens = [];
		tokenStore.tokenExpires = null;
		tokenStore.refreshToken = null;
		tokenStore.refreshTokenExpires = null;
		TicketService.onLogout();
		this.onLogout();
		AuthSocketService.broadcastToTabs("logout");
		await MainSocketService.disconnect();
		await AuthSocketService.disconnect();
		await ElectronCtep.deinit();
		this.broadcastChannel.postMessage("logout");
	}

	onLogout(){
		this.isLoggedIn = false;
		router.push("/login");
		this.wfUser = new UserInfo();
		this.wfCompany = new Company();
		this.wfDossier = new Dossier();
		this.emit("logout");
	}

	async totpEnable(password:string):Promise<Blob>{
		let res = await Axios.post(`${this.totpUrl}/enable`, {password}, {responseType: "arraybuffer"});
		return new Blob([res.data], {type: "image/png"});
	}

	// Returns backup codes
	async totpValidate(code:string):Promise<string[]>{
		let res = await Axios.post(`${this.totpUrl}/validate`, {code});
		return res.data.backupCodes;
	}

	async totpDissable(password:string):Promise<void>{
		await Axios.post(`${this.totpUrl}/disable`, {password});
	}

	private async initServices() {
		this.appReady = false;
		await AuthSocketService.connect(this.wfDossier.id);
		await MainSocketService.connect(this.wfDossier.id);
		MainSocketService.emit(EVENT_SWITCH_SESSION);
		await DossierService.init();
		let data = await InitService.fetchAllAppData();
		ViewService.init(data);
		BookYearService.init(data);
		DossierConfigService.init(data);
		await UserConfigService.init(data);
		BrowseService.init(data);
		VatService.init(data);
		CurrencyService.init(data);
		JobService.init(data);
		NotificationService.init(data);
		SaleService.init(data);
		UnitService.init(data);
		BankService.init(data);
		CrmService.init(data);
		await ClosedPeriodService.init();
		await TicketService.init();
		await ElectronCtep.init();
		this.appReady = true;
	}

	private async initProfileAfterStart(){
		try{
			await this.refreshTokens();
			await this.getUserInfo();
			if (this.wfCompany.isExpired()){
				this.failedRefreshCount = 0;
				this.appReady = true;
				return;
			}
			await this.initServices();
			this.failedRefreshCount = 0;
		} catch(err){
			this.failedRefreshCount++;
			console.error("INIT FAILURE", this.failedRefreshCount, err);
			if (this.failedRefreshCount == 3){
				this.emit("init-failure");
			}
			setTimeout(() => this.init(), 1000);
			return;
		}
	}

	onPlanExpired(){
		router.push("/account");
	}

	private subscribeToSocketEvents(){
		// AuthSocketService.on(ERR_TOKEN_EXPIRED, async ()=>{
		// 	await this.refreshTokens();
		// 	AuthSocketService.disconnect();
		// 	setTimeout(async ()=>{
		// 		await AuthSocketService.connect();
		// 	}, 1000);
		// });
		// MainSocketService.on(ERR_TOKEN_EXPIRED, async ()=>{
		// 	console.log("MAIN SOCKET SERVICE REFRESH TOKENS");
		// 	await this.refreshTokens();
		// 	console.log("MAIN SOCKET SERVICE REFRESHED TOKENS");
		// 	MainSocketService.disconnect();
		// 	setTimeout(async ()=>{
		// 		console.log("CONNECTING");
		// 		await MainSocketService.connect();
		// 	}, 1000);
		// });
		AuthSocketService.on("active-until-update", (timeAsUnix:number)=>{
			this.wfCompany.ActiveUntil = new Date(timeAsUnix * 1000);;
		});
		AuthSocketService.on("is-demo-updated", (val:boolean)=>{
			this.wfCompany.IsDemo = val;
		});
		MainSocketService.on("/frontend-version", (version:string)=>{
			this.compareFrontendVersion(version);
		});
		MainSocketService.on("/ready", this.checkFrontendVersion.bind(this));
	}

	async checkFrontendVersion(){
		let newVersion = await this.getCurrentFrontendVersion();
		this.compareFrontendVersion(newVersion);
	}

	async init() {
		GoogleService.initClient();
		this.subscribeToSocketEvents();
		let searchParams = new URLSearchParams(window.location.search);
		let queryRefreshToken = searchParams.get("refreshToken");
		if (queryRefreshToken) {
			if (tokenStore.refreshToken){
				await this.logout();
			}
			tokenStore.refreshToken = queryRefreshToken;
			searchParams.delete("refreshToken");
			window.location.search = searchParams.toString();
		}
		if (tokenStore.refreshToken) {
			this.isLoggedIn = true;
			await this.initProfileAfterStart();
		}
		window.addEventListener("focus", this.checkFrontendVersion.bind(this));
	}

	public async getCurrentFrontendVersion():Promise<string> {
		if (process.env.NODE_ENV == "development"){
			return "";
		}
		try{
			let result = await Axios.get(`${ServerConfig.host}/public/frontend-version`);
			return result.data.Version;
		}catch(err){
			let hErr = err as HttpError;
			if (hErr.key == "VERSION_NOT_FOUND") {
				hErr.dontShow = true;
			}
			if (hErr.key == "FEATURE_DISABLED") {
				hErr.dontShow = true;
			}
		}
		return "";
	}

	public getLoadedFrontendVersion():string {
		let scripts = document.getElementsByTagName("script");
		for (let script of scripts){
			if (script.src.match(/\/js\/app\.[0-9a-zA-Z]+\.js$/)){
				let lastPart = script.src.split("/").pop() as string;
				return lastPart.split(".")[1];
			}
		}
		return "";
	}

	public compareFrontendVersion(newVersion:string){
		if (this.getLoadedFrontendVersion() == "" || newVersion == "") {
			return;
		}
		if (this.getLoadedFrontendVersion() == newVersion){
			return;
		}
		this.emit("new-frontend-version");
	}

	public hasAccessToMicroModule(year:number, month:number, date:number):boolean{
		if (this.wfCompany.IsDemo) {
			return false;
		}
		if (!this.wfCompany.isPermanentlyActive()) {
			return true;
		}
		if (this.wfCompany.ServicePackExpirationDate == null){
			return true;
		}
		return this.wfCompany.ServicePackExpirationDate.getTime() >= new Date(year, month-1, date).getTime();
	}

	public servicePackExpired():boolean{
		return AuthService.wfCompany.ServicePackExpirationDate !== null
		&& AuthService.wfCompany.ServicePackExpirationDate < new Date();
	}

	public async waitForAppReady(){
		if (this.appReady) return;
		return new Promise(resolve=>{
			this.appReadyPromises.push(resolve);
		});
	}

	public async getServerTime():Promise<Date> {
		let result = await UnauthenticatedClient.get(`${this.baseUrl}/time`);
		return new Date(result.data);
	}
}

export const AuthService = new AuthServiceClass();