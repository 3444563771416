import { CustomTranslation } from "@/utils/custom-translation";
import { BorderStyle } from "../documents/styled-property";
import { TextStyledProperty } from "../documents/text-styled-property";

export class SaleLayoutTotalLines {
	public HideAll:boolean = false;
	public PrintOgm:boolean = false;
	public HideDiscount:boolean = false;
	public ShowDiscountExclVat:boolean = false;
	public HideToPrintMessage:boolean = false;
	public HideTotalLabels:boolean = false;
	public HideTotalValues:boolean = false;
	public ShowTotalTotals:boolean = false;
	public HideTotalAmount:boolean = false;
	public HidePaymentBeforeDiscountConditions:boolean = false;
	public HidePartlyPaid:boolean = false;
	public HideLeftToPay:boolean = false;
	public OgmText:CustomTranslation = new CustomTranslation();
	public LeeggoedText:CustomTranslation = new CustomTranslation();
	public TotalExcVAT = new CustomTranslation();
	public VAT = new CustomTranslation();
	public TotalVAT = new CustomTranslation();
	public TotalToPayBefore = new CustomTranslation();
	public Total = new CustomTranslation();
	public PayedRecently = new CustomTranslation();
	public DiscountApplied = new CustomTranslation();
	public DiscountExclVatApplied = new CustomTranslation();
	public VatShifted = new CustomTranslation();
	public Vat2ehands = new CustomTranslation();
	public ToPayAmount = new CustomTranslation();
	public OfferValidTil = new CustomTranslation();
	public TotalBase = new CustomTranslation();
	public PaymentBeforeDiscountDateConditions = new CustomTranslation();
	public VatLinesStyle:TextStyledProperty = new TextStyledProperty();

	//additional vat rules
	public Vat44 = new CustomTranslation();
	public Vat45 = new CustomTranslation();
	public Vat46 = new CustomTranslation();
	public Vat47 = new CustomTranslation();
	public Vat47s = new CustomTranslation();
	public VatFree = new CustomTranslation();
	public VatWBTW = new CustomTranslation();
	public Tdoc = new CustomTranslation();

	// Deprecated
	public Hidden:boolean = false;

	public GrandTotalLineStyleOverride:TextStyledProperty = SaleLayoutTotalLines.getDefaultGrandTotalLineStyle();
	public UseGrandTotalLineStyleOverride:boolean = false;

	// eslint-disable-next-line max-lines-per-function
	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.Hidden = data.Hidden || false;
		this.PrintOgm = data.PrintOgm || false;
		this.OgmText = new CustomTranslation(data.OgmText);
		this.LeeggoedText = new CustomTranslation(data.LeeggoedText);
		this.TotalExcVAT = new CustomTranslation(data.TotalExcVAT);
		this.VAT = new CustomTranslation(data.VAT);
		this.TotalVAT = new CustomTranslation(data.TotalVAT);
		this.TotalToPayBefore = new CustomTranslation(data.TotalToPayBefore);
		this.Total = new CustomTranslation(data.Total);
		this.PayedRecently = new CustomTranslation(data.PayedRecently);
		this.DiscountApplied = new CustomTranslation(data.DiscountApplied);
		this.DiscountExclVatApplied = new CustomTranslation(data.DiscountExclVatApplied);
		this.VatShifted = new CustomTranslation(data.VatShifted);
		this.Vat2ehands = new CustomTranslation(data.Vat2ehands);
		this.ToPayAmount = new CustomTranslation(data.ToPayAmount);
		this.OfferValidTil = new CustomTranslation(data.OfferValidTil);
		this.TotalBase = new CustomTranslation(data.TotalBase);
		this.PaymentBeforeDiscountDateConditions = new CustomTranslation(data.PaymentBeforeDiscountDateConditions);
		this.Vat44 = new CustomTranslation(data.Vat44);
		this.Vat45 = new CustomTranslation(data.Vat45);
		this.Vat46 = new CustomTranslation(data.Vat46);
		this.Vat47 = new CustomTranslation(data.Vat47);
		this.Vat47s = new CustomTranslation(data.Vat47s);
		this.VatFree = new CustomTranslation(data.VatFree);
		this.VatWBTW = new CustomTranslation(data.VatWBTW);
		this.Tdoc = new CustomTranslation(data.Tdoc);
		this.VatLinesStyle = new TextStyledProperty(data.VatLinesStyle);
		this.HideAll = data.HideAll;
		this.HideDiscount = data.HideDiscount;
		this.ShowDiscountExclVat = data.ShowDiscountExclVat;
		this.HideToPrintMessage = data.HideToPrintMessage;
		this.HideTotalLabels = data.HideTotalLabels;
		this.HideTotalValues = data.HideTotalValues;
		this.ShowTotalTotals = data.ShowTotalTotals;
		this.HideTotalAmount = data.HideTotalAmount;
		this.HidePaymentBeforeDiscountConditions = data.HidePaymentBeforeDiscountConditions;
		this.HidePartlyPaid = data.HidePartlyPaid;
		this.HideLeftToPay = data.HideLeftToPay;
		if (data.GrandTotalLineStyleOverride) {
			this.GrandTotalLineStyleOverride = new TextStyledProperty(data.GrandTotalLineStyleOverride);
			this.UseGrandTotalLineStyleOverride = true;
		}
	}

	// eslint-disable-next-line max-lines-per-function
	public getJSON() {
		return {
			HideAll: this.HideAll,
			Hidden: this.Hidden,
			PrintOgm: this.PrintOgm,
			OgmText: this.OgmText.getJSON(),
			LeeggoedText: this.LeeggoedText.getJSON(),
			TotalExcVAT: this.TotalExcVAT.getJSON(),
			VAT: this.VAT.getJSON(),
			TotalVAT: this.TotalVAT.getJSON(),
			TotalToPayBefore: this.TotalToPayBefore.getJSON(),
			Total: this.Total.getJSON(),
			PayedRecently: this.PayedRecently.getJSON(),
			DiscountApplied: this.DiscountApplied.getJSON(),
			DiscountExclVatApplied: this.DiscountExclVatApplied.getJSON(),
			VatShifted: this.VatShifted.getJSON(),
			Vat2ehands: this.Vat2ehands.getJSON(),
			ToPayAmount: this.ToPayAmount.getJSON(),
			OfferValidTil: this.OfferValidTil.getJSON(),
			TotalBase: this.TotalBase.getJSON(),
			PaymentBeforeDiscountDateConditions: this.PaymentBeforeDiscountDateConditions.getJSON(),
			Vat44: this.Vat44.getJSON(),
			Vat45: this.Vat45.getJSON(),
			Vat46: this.Vat46.getJSON(),
			Vat47: this.Vat47.getJSON(),
			Vat47s: this.Vat47s.getJSON(),
			VatFree: this.VatFree.getJSON(),
			VatWBTW: this.VatWBTW.getJSON(),
			Tdoc: this.Tdoc.getJSON(),
			VatLinesStyle: this.VatLinesStyle.getJSON(),

			HideDiscount: this.HideDiscount,
			ShowDiscountExclVat: this.ShowDiscountExclVat,
			HideToPrintMessage: this.HideToPrintMessage,
			HideTotalLabels: this.HideTotalLabels,
			HideTotalValues: this.HideTotalValues,
			HideTotalAmount: this.HideTotalAmount,
			ShowTotalTotals: this.ShowTotalTotals,
			HidePaymentBeforeDiscountConditions: this.HidePaymentBeforeDiscountConditions,
			HidePartlyPaid: this.HidePartlyPaid,
			HideLeftToPay: this.HideLeftToPay,

			GrandTotalLineStyleOverride: this.UseGrandTotalLineStyleOverride ? this.GrandTotalLineStyleOverride.getJSON() : null
		};
	}

	public static getDefaultGrandTotalLineStyle():TextStyledProperty {
		let result = new TextStyledProperty();
		result.BackgroundColor = "#d2d2d2";
		result.Color = "#000000";
		result.Bold = true;
		result.FontSize = 10;
		result.BorderTopWidth = 1;
		result.BorderBottomWidth = 1;
		result.BorderStyle = BorderStyle.SOLID;
		result.BorderColor = "#000000";
		result.PaddingTop = 1;
		result.PaddingBottom = 1;
		result.Font = "Times New Roman";
		return result;
	}
}